import One from "../images/1.svg"
import Two from "../images/2.svg"
import Three from "../images/3.svg"
import Four from "../images/4.svg"
import Five from "../images/5.svg"
import Six from "../images/6.svg"
import Seven from "../images/7.svg"
import Eight from "../images/8.svg"
import Nine from "../images/9.svg"
import Ten from "../images/10.svg"
import Eleven from "../images/11.svg"
import Twelve from "../images/12.svg"
import Thirteen from "../images/13.svg"

export const SYLABUS = [
  {
    src: One,
    heading: "Návrh měření",
    text: "Kuba položí důležité základy pro další navázání. Zaměří se na to, jak se dostat k byznysovým potřebám, převést je do architektury měření a následně z nich vytvořit konkrétní návrh technického řešení. Nejen obecný postup, ale i konkrétní aplikace na společný projekt, obsahový web, e-commerce a SaaS. Od high-level přístupu po konkrétní tipy, jak přistupovat k návrhu datalayeru. Lektor: Jakub Kříž",
  },
  {
    src: Two,
    heading: "Konfigurace GA4 a její specifika",
    text: "Tomáš bude pokračovat v návrhu měření, a to jeho aplikací na GA4. Od základní implementace přes e-commerce až po pokročilé způsoby (cross domain, více konfigurací v rámci jednoho webu, měření aplikací, measurement protocol pro GA4). Ukáže samotné nastavení i jak na debugging. Lektor: Tomáš Baxa",
  },
  {
    src: Three,
    heading: "Nastavení reportingu v GA4",
    text: "František projde možnosti reportingu v rozhraní GA4. Poví ti, na co stačí předpřipravené přehledy, kde se uchýlit k Exploration a na co využít Data Studio. Nebude procházet jen technické nastavení reportů, ale hlavně jak nad reportingem přemýšlet. Lektor: František Rajtmajer",
  },
  {
    src: Four,
    heading: "Jak na dokumentaci",
    text: "André se zaměří na často opomíjenou oblast dokumentace. Jak se k ní postavit, aby byla jednoduše pochopitelná pro vývojáře, přehledná pro byznys a dostatečně konkrétní pro samotného analytika. Kromě způsobu, jakým k dokumentaci přistupovat, André ukáže i na nástroje, které pro vytváření dokumentace využít. Lektor: André Heller",
  },
  {
    src: Five,
    heading: "BigQuery a SQL",
    text: "Vaškové ti ukáží jak pracovat s BigQuery. Od úvodu do BQ a práce s SQL přes specifika GA4 datasetu po pokročilé queries, které pak můžeš používat na svých projektech (channel grouping, funnely, atribuce). Kromě samotné práci s daty v BigQuery Vaškové pokryjí i, jak s Google Cloud Platform pracovat – uživatelská práva, alerty a notifikace nad GA4 datasetem, či jak hlídat a omezit útraty. Lektoři: Vašek Ráš, Vašek Jelen",
  },
  {
    src: Six,
    heading: "Privacy a sběr 1st party dat",
    text: "Vašek projde privacy a různé možnosti jak řešit consent management (vlastní řešení vs. řešení třetích stran). Dále ukáže jak a proč legálně realizovat anonymní měření nezávislé na cookie liště. V další části se bude věnovat možnostem sběru 1st party dat a ukáže jak sbírat data do různých mediálních a analytických systémů mimo GA a jak nasazovat mediální tracking. Lektor: Vašek Jelen",
  },
  {
    src: Seven,
    heading: "Datová architektura",
    text: "Tomáš s Vaškem tě provedou celým procesem od surových dat až k reportovacím datasetům. I jak to celé automatizovat, aby sis ušetřil(a) práci. Kromě toho ukáží, jak Google pracuje s nápočtem unikátních uživatelů, aby to bylo efektivní. Lektoři: Tomáš Kumsta, Vašek Ráš",
  },
  {
    src: Eight,
    heading: "Pohled marketéra: vyhodnocování",
    text: "Adam projde celkovým frameworkem, jak přistupovat k vyhodnocování nejen marketingových aktivit. Probere, kdy zvolit řízený experiment, kdy dopočítávání inkrementu a jak se vypořádat se situací, kdy nemůžeme měřit napřímo to, co potřebujeme. Opět vše navázané na společný projekt s probráním i specifických metrik pro jiné typy firem. Lektor: Adam Šilhan",
  },
  {
    src: Nine,
    heading: "Pohled produktu: CRO + AB testování",
    text: "Jarda tě provede postupem CRO specialisty – od identifikace problémových míst přes kvalitativní / kvantitativní research až k návrhu experimentu a jeho vyhodnocení. Marek naváže s pokročilým využitím Smartlooku k propojení kvalitativní a kvantitativní části. Lektoři: Jaroslav Vojna s doplněním Marka Čecha",
  },
  {
    src: Ten,
    heading: "Server side měření a jeho specifika",
    text: "Tomáš ti ukáže, jak pracovat s SGTM v kombinaci s klasickým GTM, případně measurement protocol. SGMT proberete od návrhu struktury, nasazení, přes přehled funkcí, pokročilé možnosti až po debugging. Projdete si i některé typické scénáře jako je server side nasazování FB Conversion API, cookie lišty, import do BQ. Lektor: Tomáš Baxa",
  },
  {
    src: Eleven,
    heading:
      "Zavádění datové kultury, vytváření mostů mezi analytiky a dalšími odděleními",
    text: "Tony s tebou projde nejen, jak zaváděl datovou kulturu v Livesportu, ale i potřebný teoretický základ, který ti s tím pomůže. K čemu to je, co k tomu budeš potřebovat a jaké nástroje můžeš využít. Michal pak ukáže, jak řeší propojování analytického týmu s marketingem v Heuréce. Lektoři: Antonín Kučera s doplněním Michala Buzka",
  },
  {
    src: Twelve,
    heading: "Marketing mixed modelling",
    text: "S Markem se zaměříš na nejaktuálnější přístup vyhodnocování kampaní – Marketing Mix Modelling. Od teoretického základu, jak MMM funguje a čím se liší od atribučního modelování, přejde ke konkrétním ukázkám, jak s ním pracovat. Aby sis z toho odnesl co nejvíce, vyzkoušíš si vše na vlastních datech v aplikaci, kterou pro tebe Marek připraví.",
  },
  {
    src: Thirteen,
    heading: "Rezervní placeholder pro probrání témat víc do hloubky",
    text: "rezervní placeholder pro probrání témat víc do hloubky",
  },
]
