import Andre from "../images/photos/andre.jpg"
import Adam from "../images/photos/adam.jpg"

export const PROFILES_OVERVIEW_DATA = [
  {
    src: Andre,
    alt: "",
    personName: "André Heller",
    linkedInProfile: "//linkedin.com/in/andheller",
    text: [
      "André je Google trenérem pro Google Analytics. Má za sebou jedny z největších implementací GA4 v ČR – Českou televizi, Heuréku, CNC. Se svými přednáškami opakovaně vyhrává cenu za nejlepší session na MeasureCampu Czechia.",
    ],
  },
  {
    src: Adam,
    alt: "",
    personName: "Adam Šilhan",
    linkedInProfile: "//linkedin.com/in/adamsilhan",
    text: [
      "Zaměřuje se na interpretační část – jak správně s daty pracovat. V této oblasti dlouhodobě mentoroval například interní analytický tým v Kiwi. Jako Google trenér pro atribuce školil Google Attribution Academy. Učí webovou analytiku na Masarykově univerzitě.",
    ],
  },
]
