import Antonin from "../images/photos/antonin.jpg"
import Frantisek from "../images/photos/frantisek.jpg"
import Jaroslav from "../images/photos/jaroslav.jpg"
import Vasek from "../images/photos/vasek.jpeg"
import Jakub from "../images/photos/jakub.jpeg"
import Vaclav from "../images/photos/vaclav.jpg"
import Tomas from "../images/photos/tomas.jpg"
import MarekK from "../images/photos/marekKobulsky.jpg"
import Andre from "../images/photos/andre.jpg"
import Adam from "../images/photos/adam.jpg"
import Placeholder from "../images/placeholder_penguin.svg"

export const CARDS_DATA = [
  {
    src: Jakub,
    alt: "",
    title: "Návrh měření",
    subTitle: ["Jakub Kříž"],
    information: [
      "Kuba položí důležité základy pro další navázání. Zaměří se na to, jak se dostat k byznysovým potřebám, převést je do architektury měření a následně z nich vytvořit konkrétní návrh technického řešení. Nejen obecný postup, ale i konkrétní aplikace na společný projekt, obsahový web, e-commerce a SaaS.",
      "Od high-level přístupu po konkrétní tipy, jak přistupovat k návrhu datalayeru.",
    ],
    link: "navrh-mereni",
  },
  {
    src: Placeholder,
    alt: "",
    title: "Konfigurace GA4 a její specifika",
    subTitle: ["Tomáš Baxa"],
    information: [
      "Tomáš bude pokračovat v návrhu měření, a to jeho aplikací na GA4. Od základní implementace přes e-commerce až po pokročilé způsoby (cross domain, více konfigurací v rámci jednoho webu, měření aplikací, measurement protocol pro GA4).",
      "Ukáže samotné nastavení i jak na debugging.",
    ],
    link: "konfigurace-ga4-a-jeji-specifika",
  },
  {
    src: Frantisek,
    alt: "",
    title: "Nastavení reportingu v GA4",
    subTitle: ["František Rajtmajer"],
    information: [
      "František projde možnosti reportingu v rozhraní GA4. Poví ti, na co stačí předpřipravené přehledy, kde se uchýlit k Exploration a na co využít Data Studio.",
      "Nebude procházet jen technické nastavení reportů, ale hlavně jak nad reportingem přemýšlet.",
    ],
    link: "nastaveni-reportingu-v-ga4",
  },
  {
    src: Andre,
    alt: "",
    title: "Jak na dokumentaci",
    subTitle: ["André Heller"],
    information: [
      "André se zaměří na často opomíjenou oblast dokumentace. Jak se k ní postavit, aby byla jednoduše pochopitelná pro vývojáře, přehledná pro byznys a dostatečně konkrétní pro samotného analytika.",
      "Kromě způsobu, jakým k dokumentaci přistupovat, André ukáže i na nástroje, které pro vytváření dokumentace využít.",
    ],
    link: "jak-na-dokumentaci",
  },
  {
    src: Vaclav,
    anotherSrc: Vasek,
    anotherAlt: "",
    alt: "",
    title: "BigQuery a SQL",
    subTitle: ["Vašek Ráš, Vašek Jelen"],
    information: [
      "Vaškové ti ukáží jak pracovat s BigQuery. Od úvodu do BQ a práce s SQL přes specifika GA4 datasetu po pokročilé queries, které pak můžeš používat na svých projektech (channel grouping, funnely, atribuce).",
      "Kromě samotné práci s daty v BigQuery Vaškové pokryjí i, jak s Google Cloud Platform pracovat – uživatelská práva, alerty a notifikace nad GA4 datasetem, či jak hlídat a omezit útraty.",
    ],
    link: "bigquery-a-sql",
  },
  {
    src: Vasek,
    alt: "",
    title: "Privacy a sběr 1st party dat",
    subTitle: ["Vašek Jelen"],
    information: [
      "Vašek projde privacy a různé možnosti jak řešit consent management (vlastní řešení vs. řešení třetích stran). Dále ukáže jak a proč legálně realizovat anonymní měření nezávislé na cookie liště. V další části se bude věnovat možnostem sběru 1st party dat a ukáže jak sbírat data do různých mediálních a analytických systémů mimo GA a jak nasazovat mediální tracking.",
    ],
    link: "privacy-a-sber-1st-party-dat",
  },
  {
    src: Tomas,
    alt: "",
    anotherSrc: Vaclav,
    anotherAlt: "",
    title: "Datová architektura",
    subTitle: ["Tomáš Kumsta, Vašek Ráš"],
    information: [
      "Tomáš s Vaškem tě provedou celým procesem od surových dat až k reportovacím datasetům. I jak to celé automatizovat, aby sis ušetřil(a) práci.",
      "Kromě toho ukáží, jak Google pracuje s nápočtem unikátních uživatelů, aby to bylo efektivní.",
    ],
    link: "datova-architektura",
  },
  {
    src: Adam,
    alt: "",
    title: "Pohled marketéra: vyhodnocování",
    subTitle: ["Adam Šilhan"],
    information: [
      "Adam projde celkovým frameworkem, jak přistupovat k vyhodnocování nejen marketingových aktivit. Probere, kdy zvolit řízený experiment, kdy dopočítávání inkrementu a jak se vypořádat se situací, kdy nemůžeme měřit napřímo to, co potřebujeme.",
      "Opět vše navázané na společný projekt s probráním i specifických metrik pro jiné typy firem.",
    ],
    link: "pohled-marketera-vyhodnocovani",
  },
  {
    src: Jaroslav,
    alt: "",
    title: "Pohled produktu: CRO + AB testování",
    subTitle: ["Jaroslav Vojna s doplněním Marka Čecha"],
    information: [
      "Jarda tě provede postupem CRO specialisty – od identifikace problémových míst přes kvalitativní / kvantitativní research až k návrhu experimentu a jeho vyhodnocení.",
      "Marek naváže s pokročilým využitím Smartlooku k propojení kvalitativní a kvantitativní části.",
    ],
    link: "pohled-produktu-cro-ab-testovani",
  },
  {
    src: Placeholder,
    alt: "",
    title: "Server side měření a jeho specifika",
    subTitle: ["Tomáš Baxa"],
    information: [
      "Tomáš ti ukáže, jak pracovat s SGTM v kombinaci s klasickým GTM, případně measurement protocol. SGMT proberete od návrhu struktury, nasazení, přes přehled funkcí, pokročilé možnosti až po debugging.",
      "Projdete si i některé typické scénáře jako je server side nasazování FB Conversion API, cookie lišty, import do BQ.",
    ],
    link: "server-side-mereni-a-jeho-specifika",
  },
  {
    src: Antonin,
    alt: "",
    title:
      "Zavádění datové kultury, vytváření mostů mezi analytiky a dalšími odděleními",
    subTitle: ["Antonín Kučera s doplněním Michala Buzka"],
    information: [
      "Tony s tebou projde nejen, jak zaváděl datovou kulturu v Livesportu, ale i potřebný teoretický základ, který ti s tím pomůže. K čemu to je, co k tomu budeš potřebovat a jaké nástroje můžeš využít.",
      "Michal pak ukáže, jak řeší propojování analytického týmu s marketingem v Heuréce.",
    ],
    link: "zavadeni-datove-kultury",
  },
  {
    src: MarekK,
    alt: "",
    title: "Marketing Mix Modelling",
    subTitle: ["Marek Kobulský"],
    information: [
      "S Markem se zaměříš na nejaktuálnější přístup vyhodnocování kampaní – Marketing Mix Modelling.",
      "Od teoretického základu, jak MMM funguje a čím se liší od atribučního modelování, přejde ke konkrétním ukázkám, jak s ním pracovat.",
      "Aby sis z toho odnesl co nejvíce, vyzkoušíš si vše na vlastních datech v aplikaci, kterou pro tebe Marek připraví.",
    ],
    link: "marketing-mix-modelling",
  },
  {
    src: Placeholder,
    alt: "",
    title: "rezervní placeholder pro probrání témat víc do hloubky",
    subTitle: [""],
    information: ["rezervní placeholder pro probrání témat víc do hloubky"],
  },
]
