import React, { useState } from "react"
import {
  Banner,
  Layout,
  ProfilesOverview,
  OfferSection,
  OrderFormWrapper,
  Subscribe,
  HalfImageBlock,
  IconText,
  Cards,
  Accordion,
  ContactForm,
  HelmetWrapper,
} from "../components"
import {
  IconTextIntroductionSection,
  InnerFloeContainer,
  TopBottomFloeWrapper,
  H2,
} from "@igloonet-web/shared-ui"
import { ICON_TEXT_INTRODUCTION } from "../model/icon-text-introduction-data"
import { PROFILES_OVERVIEW_DATA } from "../model/profiles-overview-data"
import { ANOTHER_LECTURERS } from "../model/another-lecturers-data"
import { OFFER_SECTION_DATA, PickedRadio } from "../model/offer-section-data"
import NumbersBlock from "@igloonet-web/shared-ui/components/numbers-block"
import { NUMBERS_BLOCK } from "../model/numbers-block-data"
import { Col, Container, Row } from "reactstrap"
import IconBoard from "../images/board-icons.svg"
import styled from "styled-components"
import OgImage from "../images/og_image.png"
import { CARDS_DATA } from "../model/cards-data"
import { SYLABUS } from "../model/accordion-sylabus"

const IconWrapper = styled.div`
  width: 100%;
  height: auto;
`

const Index: React.FC = () => {
  const [pickedRadio, setPickedRadio] = useState(PickedRadio.Meeting)

  const onButtonClick = (value: PickedRadio) => setPickedRadio(value)

  return (
    <Layout>
      <HelmetWrapper
        title="Semestrální vzdělávání pro analytiky"
        descriptionContent="Jediné dlouhodobé vzdělávání webových a digitálních analytiků 
      v České republice. 12 celodenních školení s maximálně 15 účastníky na 
      obdobné úrovni. Individuální posun projektu díky mentorovi."
        ogTitleContent="Analytics Academy - Jediné dlouhodobé vzdělávání webových a digitálních analytiků v České republice"
        ogImage={OgImage}
      />

      <Banner />
      {/* <Banner
        style={BannerStyle.AA}
        blackHeading="Vzdělávací program od analytiků pro analytiky"
        text="Nejsme továrna, pracujeme raději pro menší množství klientů pořádně. Nebudeme se na vás učit, naši specialisté mají průměrně 6 let zkušeností v oboru."
      /> */}

      <div id="analytics-konci">
        <TopBottomFloeWrapper>
          <InnerFloeContainer>
            <IconTextIntroductionSection
              negativeMargin
              data={ICON_TEXT_INTRODUCTION}
            />
          </InnerFloeContainer>
        </TopBottomFloeWrapper>
      </div>

      <div id="temata">
        <Container>
          <H2>Témata školení, workshopů</H2>
          <Row>
            <Col lg={6}>
              <p>
                Začínáme v polovině ledna. Maximální počet 15 účastníků přinese
                vysokou praktičnost školení. Konkrétní harmonogram budeme
                připravovat dle jejich zájmu a priorit. Vzhledem ke konci{" "}
                <strong>Universal Analytics</strong> ale očekáváme, že letos
                bude hlavní záběr na <strong>GA4</strong>, jejich specifika a
                jak s nimi správně pracovat.
              </p>
              <p>
                Z technických témat chceme kromě samotných <strong>GA4</strong>{" "}
                projít <strong>BigQuery</strong> a <strong>GCP</strong>{" "}
                ekosystém, <strong>server-side měření</strong> a možnosti
                obohacování dat. Vše v kontextu současných privacy aktivit tak,
                aby bylo měření i do budoucna udržitelné.
              </p>
              <p>
                Z interpretační části přineseme pohled na vyhodnocování kampaní
                a zákaznickou analytiku. Principy správného{" "}
                <strong>A/B testování</strong>, rozdíly mezi{" "}
                <strong>atribucí</strong> a <strong>inkrementalitou</strong>,
                případně alternativní možnosti vyhodnocování aktivit.
              </p>
              <p>
                Rádi bychom se věnovali také softovým tématům zavádění a
                rozvíjení datové kultury v organizaci, demokratizaci dat a
                datovému storytellingu.
              </p>
              <p className="m-0">
                V případě většího zájmu pojedeme už letos paralelně více
                specializací.
              </p>
            </Col>
            <Col xs={6} className="mt-4 ml-auto">
              <IconWrapper>
                <IconBoard width={"75%"} height={"75%"} />
              </IconWrapper>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="harmonogram">
        <div className="d-none d-md-block mt-5 pt-5">
          <Cards
            heading="Minulý ročník"
            subHeading="Všechna témata si vyzkoušíme na společném projektu tak, aby byl pro tebe výstup co nejpraktičtější."
            data={CARDS_DATA}
          />
        </div>
        <div className="d-md-none mr-3 ml-5">
          <Accordion
            mainTitle="Minulý ročník"
            subTitle="Všechna témata si vyzkoušíme na společném projektu tak, aby byl pro tebe výstup co nejpraktičtější."
            data={SYLABUS}
          />
        </div>
      </div>

      <TopBottomFloeWrapper>
        <InnerFloeContainer>
          <ProfilesOverview
            id="za-projektem-stoji"
            title="Za projektem stojí"
            data={PROFILES_OVERVIEW_DATA}
          />
        </InnerFloeContainer>
      </TopBottomFloeWrapper>

      <ProfilesOverview
        id="dalsi-lektori"
        title="Představení dalších lektorů a mentorů"
        data={ANOTHER_LECTURERS}
      />

      <Subscribe />

      <div id="mentoring" className="mt-5">
        <HalfImageBlock />
      </div>

      <OfferSection
        onButtonClick={onButtonClick}
        data={OFFER_SECTION_DATA}
      ></OfferSection>

      <IconText />

      <div id="prihlaseni">
        <OrderFormWrapper
          heading="Přihlášky k příštímu ročníku budeme teprve otevírat."
          subHeading="Nezávazně registrovat:"
          picked={pickedRadio}
        />
      </div>

      <div id="faq">
        <NumbersBlock heading="FAQ" data={NUMBERS_BLOCK} />
      </div>

      <div id="kontakt">
        <ContactForm
          heading="Máš zájem pouze o dílčí téma? Můžeme se domluvit na konzultaci či workshopu pro celou firmu"
          subheading="Napiš Adamovi"
        />
      </div>
    </Layout>
  )
}
export default Index
