import Screens from "../images/screens.svg"
import TagSettings from "../images/tag_settings.svg"

export const ICON_TEXT_INTRODUCTION = [
  {
    heading: "Pro koho je AA?",
    text: [
      "Analytics Academy je pro analytiky, co se chtějí posunout dát. Primárně pro mediory, kteří už chápou základy a mají něco za sebou.",
      "Je jedno, jestli pracuješ v korporátu, startupu nebo agentuře. Pokud ti chybí rady seniorních kolegů, AA je pro tebe. Připrav se na GA4 a předejdi zbytečným fuckupům. Ať už na úrovni implementace, nebo práce s daty.",
    ],
    src: Screens,
    alt: "",
  },
  {
    heading: "Co tě čeká?",
    text: [
      "Tříměsíční program – začínáme v polovině ledna.",
      "12 celodenních školení.",
      "Individuální přístup – max. 15 lidí v programu.",
      "Posun projektu díky mentorovi.",
    ],
    src: TagSettings,
    alt: "",
  },
]
