import Antonin from "../images/photos/antonin.jpg"
import Frantisek from "../images/photos/frantisek.jpg"
import Jaroslav from "../images/photos/jaroslav.jpg"
import Vasek from "../images/photos/vasek.jpeg"
import Jakub from "../images/photos/jakub.jpeg"
import Marek from "../images/photos/marek.jpg"
import Vaclav from "../images/photos/vaclav.jpg"
import Michal from "../images/photos/michal.jpg"
import Tomas from "../images/photos/tomas.jpg"
import MarekK from "../images/photos/marekKobulsky.jpg"

export const ANOTHER_LECTURERS = [
  {
    src: Antonin,
    alt: "",
    personName: "Antonín Kučera",
    text: [
      "Tonda vystudoval na VŠE Informační management. Po škole se nějakou dobu věnoval datům a on-line marketingu na volné voze.",
      "V roce 2015 nastoupil do Livesportu jako webový analytik a dnes tam vede celé Business Intelligence oddělení, které za necelých 7 let vybudoval. Nejvíc ho baví spojení dat a businessu, především evangelizace a aplikace dat v praxi.",
    ],
    linkedInProfile: "//linkedin.com/in/antoninkucera",
  },
  {
    src: Frantisek,
    alt: "",
    personName: "František Rajtmajer",
    text: [
      "František se zabývá webovou analytikou a PPC správou na volné noze. Díky své praxi propojuje tyto dva obory, a pomáhá tak firmám řídit on-line marketing na základě dat.",
      "V rámci Digisemestru on-line vede lekce webové analytiky. Žije v Plzni, vaří si vlastní pivo a píše blog nazakladedat.cz.",
    ],
    linkedInProfile: "//linkedin.com/in/frantisekrajtmajer",
  },
  {
    src: Jaroslav,
    alt: "",
    personName: "Jaroslav Vojna",
    text: [
      "Jardu baví hledat odpovědi na byznysové otázky v datech a uvádět poznatky (od CX po marketing) do praxe. Primárně se zaměřuje na interpretační analytiku – webovou, produktovou a zákaznickou.",
      `Má zkušenosti z řady mezinárodních projektů, například Avastu a Footshopu. V současnosti analyzuje „SaaSová“ data v Digismoothie.`,
    ],
    linkedInProfile: "//linkedin.com/in/jaroslav-vojna-39b3961b",
  },
  {
    src: Vasek,
    alt: "",
    personName: "Vašek Jelen",
    text: [
      "Vašek se více než 10 let věnuje digitální analytice – od nastavování měření a vizualizace po interpretaci marketingových dat. Skrze klientské projekty a školení pomáhá firmám a mediálním agenturám mít v datech pořádek a umět je naplno využít. Jeho tech stack zahrnuje jak GA 360/GA4 360, tak Adobe Analytics a další související nástroje.",
      "Po letech na volné noze spoluzaložil analytické studio MeasureDesign, které kromě analytických projektů pro firmy a mediální agentury vzdělává nové analytiky a analytičky.",
    ],
    linkedInProfile: "//linkedin.com/in/vaclavjelen",
  },
  {
    src: Jakub,
    alt: "",
    personName: "Jakub Kříž",
    text: [
      "Jakub se narodil spolu s internetem. Svůj první linuxový server zprovoznil, když mu bylo 13 a od té doby se věnuje programování. Posledních 10 let spolupracuje s firmou Optimics, kde se specializuje na on-line analytické technologie a etický přístup k trackingu.",
      "Je autorem nespočtu rozsáhlých implementací analytických a marketingových nástrojů napříč internetem a je prakticky nemožné se při brouzdání po webu nesetkat s jeho prací. Létá na paraglidu, staví pivovar, hraje v divadle a má rád dobré techno.",
    ],
    linkedInProfile: "//linkedin.com/in/jakubkrizanalytics",
  },
  {
    src: Marek,
    alt: "",
    personName: "Marek Čech",
    text: [
      "Od roku 2006 se Marek pohybuje v oblasti webových projektů. V roce 2018 se dvěma kamarády založil analytické studio Digitální architekti. Zde řídí pokročilé implementace GA, GTM a Smartlooku. Za tu dobu už ví, že se dá změřit úplně všechno, ale klíčové je silné byznysové zadání od klienta.",
      "Ve svých implementacích často hledá ta nejjednodušší řešení a možnosti automatizace, kde si pomáhá Google Apps Scriptem. Marek se zajímá o hodnocení datové a analytické vyspělosti firem pomocí Marketing Meteru.",
    ],
    linkedInProfile: "//linkedin.com/in/marekcech/",
  },
  {
    src: Michal,
    alt: "",
    personName: "Michal Procházka",
    text: [
      "Michal má za sebou bohatou datovou historii. Studoval ve Zlíně, prodával weby, prošel korporátem a v agentuře stavěl produkt na kombu KBC + Gooddata v době, kdy to byl noBrainer. Poté si vyzkoušel práci pro US startup a teď dodává datové výstupy pro Sportisimo.",
      "Data bere jako produkt, jehož použití má mít návratnost. Tam, kde je to potřeba, spojuje business a tech lidi, aby se věci posouvaly kupředu.",
    ],
    linkedInProfile: "//linkedin.com/in/prochazkamichal",
  },
  {
    src: Vaclav,
    alt: "",
    personName: "Václav Ráš",
    text: [
      "Vašek pracuje s daty především na databázové úrovni. Baví ho jak datová čistota a automatizované datové transformace v BigQuery, tak složitější analýzy v Pythonu.",
      "Využívá zkušeností, které nabral ve velkých korporátech, jako jsou O2, Creditas či Česká televize, ale i u menších startupů, jako je například Qerko.",
    ],
    linkedInProfile: "//linkedin.com/in/vaclavras/",
  },
  {
    src: Tomas,
    alt: "",
    personName: "Tomáš Kumsta",
    text: [
      "Tomáš se dlouhodobě zaměřuje na datovou analytiku napojenou především na online byznys. Profesní zkušenosti nabíral hlavně v Medio Interactive (RIP) a Seznam.cz.",
      "Momentálně působí v Livesportu, kde se aktivně věnuje velkým datům a rozjezdu sportovního zpravodajství. To ho zavedlo do hlubin GA4 360 a BigQuery. Doufá, že se vynoří a zůstane příčetný, aby mohl dál na workshopech předávat své zkušenosti.",
    ],
    linkedInProfile: "//linkedin.com/in/tkumsta",
  },
  {
    src: MarekK,
    alt: "",
    personName: "Marek Kobulský",
    text: [
      "Marek se pohybuje v online marketingu od roku 2014. Má zkušenosti jako Data Scientist, CMO a CTO.",
      "Pracoval jak na straně klienta, tak pro agenturu, ale i jako freelance. Díky tomu dokáže hezky kombinovat byznysové požadavky s analytickou utopií. Umí být na všechno sám, ale i vést 10členný tým. Přednášel v Londýně, Varšavě, Kyjevě, nebo na Havaji.",
      "Od 4 let hraje na piáno a soutěží na mezinárodní úrovni. Spoluzaložil projekt MarketingIntelligence.io, ve kterém řeší oblasti zákaznické a produktové analytiky, atribučního modelování, nebo Media Mix Modeling pro klienty u nás  i v zahraničí.",
    ],
    linkedInProfile: "//linkedin.com/in/marek-kobulsk%C3%BD-0349229a/",
  },
]
