export enum PickedRadio {
  Meeting = "setkání",
  MentorMeeting = "setkání s mentorem",
}

export const OFFER_SECTION_DATA = [
  {
    heading: "Čistě školení",
    text: [
      "12 celodenních školení (jídlo zajištěno)",
      "materiály pro další rozšiřování znalostí",
      "užší propojení s analytickou komunitou",
      "po setkání pokračujeme na společném pivku",
      "podpůrná slack skupina",
    ],
    to: "#prihlaseni",
    picked: PickedRadio.Meeting,
  },
  {
    heading: "Školení + mentoring",
    text: [
      "12 celodenních školení (jídlo zajištěno)",
      "materiály pro další rozšiřování znalostí",
      "užší propojení s analytickou komunitou",
      "po setkání pokračujeme na společném pivku",
      "podpůrná slack skupina",
    ],
    anotherText: [
      "každých 14 dní dvě hodiny 1:1 mentoringu po dobu Analytics Academy (přestávka o svátcích)",
    ],
    to: "#prihlaseni",
    picked: PickedRadio.MentorMeeting,
  },
]
