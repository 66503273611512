// TODO DOKONČIT, NEDĚLAT REVIEW, JEŠTĚ SE NEBUDE POUŽÍVAT
import One from "../../../igloonet/src/images/hosting/1.svg"
import Two from "../../../igloonet/src/images/hosting/2.svg"
import Three from "../../../igloonet/src/images/hosting/3.svg"
import Four from "../../../igloonet/src/images/hosting/4.svg"
import Five from "../../../igloonet/src/images/hosting/5.svg"
import Six from "../../../igloonet/src/images/hosting/6.svg"
import Seven from "../images/7.svg"
import Eight from "../images/8.svg"
// import Nine from "../images/9.svg"
// import Ten from "../images/10.svg"

export const NUMBERS_BLOCK = [
  {
    src: One,
    heading: "Jaká je ideální úroveň účastníka?",
    text: "Medior, který nechce zůstat na místě a chce se posunout dál.",
  },
  {
    src: Two,
    heading: "Takže stačí, když projdu AA a bude ze mě senior?",
    text: "Tak jednoduché to bohužel nebude. Dost to ale urychlí tvoji cestu.",
  },
  {
    src: Three,
    heading:
      "Nestane se, že mě „při výuce” bude brzdit někdo, kdo vůbec nemá základy?",
    text: "Je pro nás důležité, aby v akademii byli lidé s podobnými očekáváními i úrovní znalostí. Po přihlášení to budeme s každým řešit a každý taky bude muset projít vstupním testem. Pokud by někdo potřebnou úroveň nesplňoval, nabídneme předškolení a zadání další látky k dostudování.",
  },
  {
    src: Four,
    heading:
      "Rád bych se AA účastnil, ale nepracuji ve firmě, která by ji za mě zaplatila. Co můžu dělat?",
    text: "Ozvi se nám i tak a uvidíme, třeba něco vymyslíme.",
  },
  {
    src: Five,
    heading:
      "Nutně potřebujeme analytiky, na AA bychom klidně někoho poslali, ale nemáme koho. Nevíte o někom?",
    text: "Viz bod 4, třeba něco vymyslíme.",
  },
  {
    src: Six,
    heading: "Kde to všechno bude, jak to bude probíhat?",
    text: "Většina programu by měla být naživo v Praze, s následným posezením na pivku večer. V případě zahraničních lektorů či při hygienických omezeních pak on-line",
  },
  {
    src: Seven,
    heading: "Jak to bude s občerstvením, obědem?",
    text: "Občerstvení a oběd je v ceně, večer pak každý za sebe :)",
  },
  {
    src: Eight,
    heading: "Jak vypadá ukončení?",
    text: "Závěrečný test, kdy ti nejlepší získají zvláštní ocenění a další vzdělávací materiály. Stejně tak mentoři nominují menteeho s největším posunem na projektu, který si poté odnese vzdělávací cenu.",
  },
]
