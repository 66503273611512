import {
  ContainerBackground,
  ContainerBackgroundStyle,
  H2,
} from "@igloonet-web/shared-ui"
import React from "react"
import { Col, Row } from "reactstrap"
import styled from "styled-components"

type Props = {
  style?: ContainerBackgroundStyle
  heading: string
  data: Array<{
    src: string
    heading: string
    text: string
  }>
}

const Img = styled.img`
  max-width: 60px;
  height: auto;
`

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    column-count: 2;
    column-gap: 2rem;
  }
`

const NumbersBlock: React.FC<Props> = ({ style, heading, data }) => {
  return (
    <ContainerBackground style={style}>
      <H2 className="pb-lg-4">{heading}</H2>
      <Wrapper>
        {data.map((item, index) => {
          return (
            <Row key={index} className="pb-5">
              <Col xs={2} sm={2}>
                <Img src={item.src} alt="" />
              </Col>
              <Col xs={10} xl={8}>
                <h3 className="pb-3">{item.heading}</h3>
                <p>{item.text}</p>
              </Col>
            </Row>
          )
        })}
      </Wrapper>
    </ContainerBackground>
  )
}

export default NumbersBlock
